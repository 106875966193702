import React, { useContext } from "react";
import { languageContext } from "../../../context/LanguageContext";
import Layout from "../../layout/layout";

const Questionnaire: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Layout title={{ nl: "Vragenlijst", en: "Questionnaire" }}>
      {language === "nl" ? <DutchQuestionnaire /> : <EnglishQuestionnaire />}
    </Layout>
  );
};

const DutchQuestionnaire = () => (
  <iframe
    src="https://docs.google.com/forms/d/e/1FAIpQLSfDIttHwJiPGpXCm_Qpascq7LVYB1cPM0QrtQ6uuYBiv6oajA/viewform?embedded=true"
    width="100%"
    height="5145"
    frameBorder="0"
    marginHeight={0}
    marginWidth={0}
  >
    Laden…
  </iframe>
);

const EnglishQuestionnaire = () => (
  <iframe
    src="https://docs.google.com/forms/d/e/1FAIpQLSfNw4lCLXI83eRhIlNJnKF7NvyZR8yUT2qUS-KKT0AYl2jbsQ/viewform?embedded=true"
    width="100%"
    height="5186"
    frameBorder="0"
    marginHeight={0}
    marginWidth={0}
  >
    Laden…
  </iframe>
);

export default Questionnaire;
